<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="7">
            <b-media class="d-flex flex-column" no-body>
              <b-media-aside>
                <b-link>
                  <b-img ref="previewEl" rounded :src="profileImage && profileImage !== null
                      ? profileImage
                      : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'
                    " height="80" />
                </b-link>
              </b-media-aside>
              <b-media-body class="mt-75 ml-75">
                <b-button variant="primary" size="sm" class="mb-75 mr-75" @click="handleClickProfileImage">
                  Upload
                  <div style="display: none">
                    <b-form-file v-model="profileImage" id="fileUpload" accept="image/*"
                      @input="handleFileChange($event, 'profileimage')" />
                  </div>
                </b-button>
                <b-button variant="outline-secondary" size="sm" class="mb-75 mr-75" @click="removeprofileImage">
                  Reset
                </b-button>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="5" :style="role == 'admin' ? '' : 'display:none'">
            <b-form-group>
              <label>Designation</label>
              <b-form-input v-model="designation_role" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Designation" />
            </b-form-group>
          </b-col>
          <b-col md="12"></b-col>

          <b-col md="4">
            <b-form-group>
              <label>Name</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Name">
                <b-form-input v-model="name" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Name" maxlength="50" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Surname</label>
              <b-form-input v-model="surname" placeholder="Enter Name" maxlength="50" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Father/Husband Name</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="father/husband name">
                <b-form-input v-model="fathername" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Name" maxlength="50" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Email</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required|email" name="Email">
                <b-form-input v-model="email" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Email" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Mobile</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Mobile">
                <b-form-input v-model="mobile" type="number|10" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Mobile" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Birth Date</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Birth Date">
                <flat-pickr v-model="birthdate" placeholder="Select Date" class="form-control" :config="{
                  enableTime: false,
                  dateFormat: 'd/m/Y',
                  maxDate: new Date(),
                }" style="background: transparent" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Gender</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Gender">
                <v-select v-model="gender" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name"
                  :options="['Male', 'Female']" placeholder="None" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4" :style="ifEdit == true ? '' : 'display:none'">
            <b-form-group>
              <label>User name (ID)</label>
              <b-form-input v-model="username" disabled :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Username" />
            </b-form-group>
          </b-col>
          <b-col md="4" :style="ifEdit == false ? '' : 'display:none'">
            <b-form-group>
              <label>Password</label>
              <label style="color: red !important">*</label>

              <validation-provider #default="{ errors }" :rules="ifEdit == false ? 'required|password' : ''"
                name="Password">
                <b-input-group class="input-group-merge">
                  <b-form-input v-model="password" placeholder="Enter Password" :type="passwordFieldTypeNew" />

                  <b-input-group-append is-text>
                    <feather-icon :icon="passwordToggleIconNew" class="cursor-pointer" @click="togglePasswordNew" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Joining Date</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Joining Date">
                <flat-pickr v-model="joiningdate" placeholder="Select Date" class="form-control" :config="{
                  dateFormat: 'd/m/Y',
                }" style="background-color: transparent" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4" :style="ifEdit ? '' : 'display:none'">
            <b-form-group>
              <label>Resign Date</label>
              <flat-pickr v-model="resigndate" placeholder="Select Date" class="form-control" :config="{
                dateFormat: 'd/m/Y',
              }" style="background-color: transparent" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Marital Status</label>
              <v-select v-model="maritalstatus" placeholder="None" :options="['Married', 'Unmaried']" />
            </b-form-group>
          </b-col>
          <b-col md="4" :style="maritalstatus == 'Married' ? '' : 'display:none'">
            <b-form-group>
              <label>Anniversary Date</label>
              <flat-pickr v-model="anniversarydate" placeholder="Select Date" class="form-control" :config="{
                dateFormat: 'd/m/Y',
                maxDate: new Date(),
              }" style="background-color: transparent" />
            </b-form-group>
          </b-col>

          <b-col md="12" class="mb-1 mt-1">
            <span style="font-weight: 500; font-size: 1.285rem">Current Address</span>
            <!-- <b-card-code title="Current Address" no-body>
              <b-col></b-col>
            </b-card-code> -->
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Address Line 1</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Address Line 1">
                <b-form-input v-model="curaddressline1" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Address Line 1" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Address Line 2</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Address Line 2">
                <b-form-input v-model="curaddressline2" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Address Line 2" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Pin Code</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required|digits:6" name="Pin Code">
                <b-form-input v-model="curpincode" maxlength="6" @input="clickPincode($event)"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" type="number" placeholder="Enter Pin Code" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>City</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="City">
                <v-select v-model="curcity" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="curcityOption"
                  @input="clickArea($event)" placeholder="None" label="City" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Area</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Area">
                <v-select v-model="curarea" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="curareaOption"
                  placeholder="None" label="PostOfficeName" @input="handleCurPincode()" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Taluka</label>
              <b-form-input v-model="curtaluka" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Taluka" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>State</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="State">
                <v-select v-model="curstate" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="stateOptions"
                  placeholder="None" label="State" @input="handleCurCity()" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12" class="mb-1 mt-1">
            <span style="font-weight: 500; font-size: 1.285rem">Permanent Address</span>
            <!-- <b-card-code title="Current Address" no-body>
              <b-col></b-col>
            </b-card-code> -->
            <b-form-checkbox @input="handlePermanent" class="ml-2" inline>
              Same As Current Address
            </b-form-checkbox>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Address Line 1</label>
              <b-form-input v-model="peraddressline1" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Address Line 1" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Address Line 2</label>
              <b-form-input v-model="peraddressline2" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Address Line 2" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Pin Code</label>
              <validation-provider #default="{ errors }" rules="digits:6" name="pin Code">
                <b-form-input v-model="perpincode" maxlength="6" @input="clickPerPincode($event)"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" type="number" placeholder="Enter Pin Code" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>City</label>
              <v-select v-model="percity" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="City"
                :options="percityOption" @input="clickPerArea($event)" placeholder="None" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Area</label>
              <v-select v-model="perarea" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perareaOption"
                placeholder="None" label="PostOfficeName" @input="handlePincode()" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Taluka</label>
              <b-form-input v-model="pertaluka" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Taluka" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>State</label>
              <v-select v-model="perstate" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="stateOptions"
                placeholder="None" label="State" @input="handleCity()" />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-card-code title="" no-body>
              <b-col></b-col>
            </b-card-code>
          </b-col>
          <b-col md="4" :style="role == 'admin' ? '' : 'display:none'">
            <b-form-group>
              <label>Commission Type</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" :rules="role == 'admin' ? 'required' : ''"
                name="Commission Type">
                <v-select v-model="commissiontype" label="type" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="None" :options="commissionOption" @input="handlecommission($event)" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4" :style="role == 'admin' ? '' : 'display:none'">
            <b-form-group>
              <label>Commission Rank</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" :rules="role == 'admin' ? 'required' : ''"
                name="Commission Type">
                <v-select v-model="commission_rank" label="rank" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="None" :options="rankoptions" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- <b-col md="4">
            <b-form-group>
              <label>Branch</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="branch"
              >
                <v-select
                  v-model="branch"
                  label="name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="None"
                  :options="branchoption"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <b-col md="4" v-if="loginData.role == 'admin' || !ifEdit || pageId != loginData.id">
            <b-form-group>
              <label>Parent</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Parent">
                <v-select v-model="parentcp" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="parentcpoption" placeholder="None" label="fullname">
                  <template #option="{ fullname, profile_image, username, color }">
                    <b-avatar :src="getprofileImage(profile_image)" />
                    <span :class="'font-weight-bolder text-' + color">
                      {{ fullname }} ({{ username }})</span>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="loginData.role == 'admin' || !ifEdit || pageId != loginData.id">
            <b-row>
              <b-col md="3">
                <label>Email</label>
                <b-form-input disabled v-model="parentcp.email" /></b-col>
              <b-col md="3">
                <label>Phone No</label>
                <b-form-input disabled v-model="parentcp.mobile" /></b-col>
              <b-col md="3">
                <label>Username</label>
                <b-form-input disabled v-model="parentcp.username" /></b-col>
              <b-col md="3">
                <label>Photo :</label>
                <b-media-aside>
                  <b-link>
                    <b-img rounded :src="parentcp.profile_image
                        ? parentcp.profile_image
                        : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'
                      " height="80" />
                  </b-link> </b-media-aside></b-col>
            </b-row>
          </b-col>
          <b-col md="12">
            <b-card-code title="Bank Details" no-body>
              <b-col></b-col>
            </b-card-code>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Pan No</label>
              <b-form-input v-model="panno" maxlength="10" @input="validatePanClick($event)"
                placeholder="Enter PAN No" />
              <small v-if="pannoValidate == false" class="text-danger">Enter Valid PAN No</small>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Aadhar No</label>
              <!-- <label style="color: red !important">*</label> -->
              <!-- <validation-provider #default="{ errors }" rules="required" name="Aadhar No"> -->
              <b-form-input v-model="aadharcardno" maxlength="14" @input="validateAadharClick($event)"
                placeholder="Enter Aadhar No" />
              <small v-if="aadharcardnoValidate == false" class="text-danger">Enter Valid Aadhar No</small>
              <!-- <small class="text-danger">{{ errors[0] }}</small> -->
              <!-- </validation-provider> -->
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Bank</label>
              <b-form-input v-model="bankname" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Bank" rules="required" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Branch</label>
              <b-form-input v-model="bankbranch" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Branch" rules="required" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Account No</label>
              <b-form-input v-model="bankaccno" type="number" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Account No" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>IFSC Code</label>
              <b-form-input v-model="ifsccode" maxlength="11" @input="validateIFSCCodeClick($event)"
                placeholder="Enter IFSC Code" />
              <small v-if="ifsccodeValidate == false" class="text-danger">Enter Valid IFSC Code</small>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Aadhar Card Front Side</label>
              <b-form-file v-model="documents.aadharfrontside" placeholder="Select Photo"
                drop-placeholder="Drop file here..." accept="image/*"
                @input="handleFile($event, 'aadharfrontside', 'channelpartner')" />
            </b-form-group>
            <attachment :data="documents.aadharfrontside" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Aadhar Card Back Side</label>
              <b-form-file v-model="documents.aadharbackside" placeholder="Select Photo"
                drop-placeholder="Drop file here..." accept="image/*"
                @input="handleFile($event, 'aadharbackside', 'channelpartner')" />
            </b-form-group>
            <attachment :data="documents.aadharbackside" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Pan Card</label>
              <b-form-file v-model="documents.pancard" placeholder="Select Photo" drop-placeholder="Drop file here..."
                accept="image/*" @input="handleFile($event, 'pancard', 'channelpartner')" />
            </b-form-group>
            <attachment :data="documents.pancard" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <!-- <validation-provider #default="{ errors }" rules="required" name="Cancel Cheque"> -->
              <label>Cancel Cheque</label>
              <b-form-file v-model="documents.cancelcheque" placeholder="Select Photo"
                drop-placeholder="Drop file here..." accept="image/*"
                @input="handleFile($event, 'cancelcheque', 'channelpartner')" />
              <!-- <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> -->
            </b-form-group>
            <attachment :data="documents.cancelcheque" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <!-- <validation-provider #default="{ errors }" rules="required" name="Cancel Cheque"> -->
              <label>Registration Document</label>
              <b-form-file v-model="documents.registration_doc" placeholder="Select Document"
                drop-placeholder="Drop file here..."
                @input="handleFile($event, 'registration_doc', 'channelpartner')" />
              <!-- <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> -->
            </b-form-group>
            <attachment :data="documents.registration_doc" />
          </b-col>
          <b-col md="4" :style="loginData.role == 'admin' || loginData.role == 'employee'
              ? ''
              : 'display:none'
            ">
            <b-form-group>
              <label>Assign To</label>
              <v-select v-model="assignto" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="assignToOption"
                placeholder="None" label="fullname">
                <template #option="{ fullname, profile_image, username, color }">
                  <b-avatar :src="getprofileImage(profile_image)" />
                  <span :class="'font-weight-bolder text-' + color">
                    {{ fullname }} ({{ username }})</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mt-1">
            <b-button variant="primary" type="submit" :disabled="flag" @click.prevent="submitForm($event)" class="mr-4">
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- <template #code>
      {{ codeType }}
    </template> -->
  </b-card-code>
</template>

<script>
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import Attachment from "../../../../components/Attechment.vue";
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import flatPickr from "vue-flatpickr-component";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BFormFile,
  BInputGroupAppend,
  BInputGroup,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BFormCheckbox,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from "@/components/axios";
import moment from "moment";
import { getWeekYearWithOptions } from "date-fns/fp";

export default {
  components: {
    BFormCheckbox,
    Attachment,
    flatPickr,
    PinchScrollZoom,
    Datepicker,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BInputGroupAppend,
    BInputGroup,
    BForm,
    BFormFile,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
  },
  data() {
    return {
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      name: "",
      surname: "",
      fathername: "",
      email: "",
      mobile: "",
      address: "",
      profileImage: "",
      area: "",
      state: "",
      city: "",
      pincode: "",
      gender: "",
      username: "",
      password: "",
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      test: "",
      joiningdate: moment(new Date()).format("DD/MM/yyyy"),
      resigndate: null,
      designation: "",
      designation_role: "",
      branch: "",
      parentcp: "",
      reportingauthorityoption: [],
      designationoption: [],
      parentcpoption: [],
      branchoption: [],
      ifEdit: "",
      id: "",
      commissiontype: "",
      commissionOption: [],
      assignToOption: [],
      assignto: "",

      curaddressline1: "",
      curaddressline2: "",
      curarea: "",
      curtaluka: "",
      curcity: "",
      curpincode: "",
      curstate: "",

      peraddressline1: "",
      peraddressline2: "",
      perarea: "",
      pertaluka: "",
      percity: "",
      perpincode: "",
      perstate: "",
      curcityOption: [],
      curareaOption: [],
      percityOption: [],
      perareaOption: [],
      birthdate: null,
      panno: "",
      pannoValidate: true,
      aadharcardno: "",
      aadharcardnoValidate: true,
      bankname: "",
      bankbranch: "",
      bankaccno: "",
      ifsccode: "",
      ifsccodeValidate: true,
      documents: {
        photo: "",
        aadharfrontside: "",
        aadharbackside: "",
        pancard: "",
        cancelcheque: "",
        registration_doc: "",
      },
      maritalstatus: "",
      anniversarydate: "",

      flag: false,
      testpan: "",
      testaadhar: "",
      fromCurState: "",
      fromState: "",

      stateOptions: [],
      passwordFieldTypeNew: "password",
      userId: "",
      loginName: "",
      role: "",
      loginData: {},
      pageId: 0,
      rankoptions: [],
      commission_rank: "",
    };
  },
  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  mounted() {
    this.loginData = JSON.parse(localStorage.getItem("userData"));
    this.role = this.loginData.role;
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    if (this.role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Channelpartner") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/master/crm/channelpartner");
            }
          } else if (item.add !== 1) {
            this.$router.push("/master/crm/channelpartner");
          }
        }
      });
    }
    this.userId = this.loginData.id;
    this.loginName = this.loginData.name;
    if (this.role !== "admin") {
      this.loginData.color = "info";
    }
    this.init();
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password";
    },
    handlecommission(e) {
      if (e) {
        this.rankoptions = e.commission_items ? e.commission_items : [];
      } else {
        this.rankoptions = [];
      }
    },
    handlePermanent(e) {
      if (e == true) {
        this.perarea = this.curarea;
        this.perstate = this.curstate;
        this.percity = this.curcity;
        this.pertaluka = this.curtaluka;
        this.perpincode = this.curpincode;
        this.peraddressline1 = this.curaddressline1;
        this.peraddressline2 = this.curaddressline2;
      } else {
        this.perarea = "";
        this.perstate = "";
        this.percity = "";
        this.pertaluka = "";
        this.perpincode = "";
        this.peraddressline1 = "";
        this.peraddressline2 = "";
      }
    },
    removeprofileImage() {
      this.profileImage = null;
    },
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getusersbyid/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          // if (json.data.length) {
          // }
          this.getEditItems(json.data.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    handleCurPincode() {
      if (this.fromCurState == true) {
        this.curpincode = this.curarea.Pincode;
      }
    },
    getprofileImage(image) {
      if (image !== undefined && image !== null && image !== "") {
        let test = image.includes(this.imagePath) ? image : this.imagePath + image;
        return test;
      }
      return image;
    },
    async handleCurCity() {
      this.curareaOption = [];
      this.curcityOption = [];
      this.fromCurState = true;
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getCityByState`,
        data: {
          State: this.curstate,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.curcityOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    handleClickProfileImage() {
      document.getElementById("fileUpload").click();
    },
    getEditItems(item) {
      item.map((item) => {
        this.pageId = item.id;
        this.name = item.name;
        this.fathername = item.fathername;
        this.surname = item.surname;
        this.email = item.email;
        this.test = item.email;
        this.mobile = item.mobile;
        this.address = item.address;
        this.area = item.area;
        this.state = item.state;
        this.city = item.city;
        this.pincode = item.pincode;
        this.gender = item.gender;
        this.username = item.username;
        this.commission_rank = item.commission_rank;
        // this.password = item.password
        this.joiningdate = item.joiningdate
          ? item.joiningdate.split("-").reverse().join("/")
          : "";
        this.resigndate =
          item.resigndate == "00/00/0000" ||
            item.resigndate == null ||
            item.resigndate == false
            ? null
            : item.resigndate.split("-").reverse().join("/");
        this.designation_role = item.designation_role;
        this.branch = this.getbranch(item.branch);
        this.parentcp = item.parent;
        this.commissiontype = item.commissiontype;
        this.handlecommission(item.commissiontype);
        this.curaddressline1 = item.curaddressline1;
        this.curaddressline2 = item.curaddressline2;
        this.curarea = item.curarea;
        this.curtaluka = item.curtaluka;
        this.curcity = item.curcity;
        this.curpincode = item.curpincode;
        this.curstate = item.curstate;
        this.peraddressline1 = item.peraddressline1;
        this.peraddressline2 = item.peraddressline2;
        this.perarea = item.perarea;
        this.pertaluka = item.pertaluka;
        this.percity = item.percity;
        this.perpincode = item.perpincode;
        this.perstate = item.perstate;

        this.birthdate = item.birthdate;
        this.testpan = item.panno;
        this.panno = item.panno;
        this.aadharcardno = item.aadharcardno;
        this.testaadhar = item.aadharcardno;
        this.bankname = item.bankname;
        this.bankbranch = item.bankbranch;
        this.bankaccno = item.bankaccno;
        this.ifsccode = item.ifsccode;
        this.documents.photo = item.photo;
        this.documents.aadharfrontside = item.aadharfrontside;
        this.documents.aadharbackside = item.aadharbackside;
        this.documents.pancard = item.pancard;
        this.documents.cancelcheque = item.cancelcheque;
        this.documents.registration_doc = item.registration_doc;

        this.profileImage = item.profile_image;
        this.maritalstatus = item.maritalstatus;
        this.assignto = item.assignto && item.assignto.length > 0 ? item.assignto[0] : {};
        this.anniversarydate =
          item.anniversarydate == "00/00/0000" ? null : item.anniversarydate;
      });
      // this.clickPincode()
      // this.clickPerPincode()
      this.location();
    },
    location() {
      this.handleCity();
      this.handleCurCity();
      this.handleCurArea();
      this.handleArea();
    },
    async handleFileChange(e, type) {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachment`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.profileImage = process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
        })
        .catch((error) => console.log(error, "error"));
    },
    init() {
      this.role = JSON.parse(localStorage.getItem("userData")).role;
      this.getbranch();
      if (this.role == "admin" || this.role == "employee") {
        this.getAllCp();
      } else {
        this.getparentcp();
      }
      this.getcommission();
      this.getState();
      this.getEmployee();
    },
    async getEmployee() {
      const reportauthority = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getEmployee`,
      };
      await axios(reportauthority)
        .then((response) => {
          this.assignToOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getAllCp() {
      const reportauthority = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getChannelpartner`,
      };
      await axios(reportauthority)
        .then((response) => {
          this.parentcpoption = response.data.data;
          if (this.role == "admin") {
            this.parentcpoption.unshift(this.loginData);
          } else {
            this.parentcpoption = this.parentcpoption.filter((item) => item.parent == 1);
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    handlePincode() {
      if (this.fromState == true) {
        this.perpincode = this.perarea.Pincode;
      }
    },
    async handleArea() {
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getAreaByCity`,
        data: {
          City: this.percity,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.perareaOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async handleCity() {
      this.perareaOption = [];
      this.percityOption = [];
      this.fromState = true;
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getCityByState`,
        data: {
          State: this.perstate,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.percityOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getState() {
      const reportauthority = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getState`,
      };
      await axios(reportauthority)
        .then((response) => {
          this.stateOptions = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    clickPerArea(e) {
      if (this.fromState == true) {
        this.handleArea();
      }
      // this.perareaOption = []
      // this.perStateData.map((item) => {
      //   if (item.City == e) {
      //     this.perareaOption.push(item.PostOfficeName)
      //   }
      // })
    },
    async clickPerPincode() {
      this.perstate = "";
      this.perarea = "";
      this.percity = "";
      this.perareaOption = [];
      this.percityOption = [];
      const regex = new RegExp(/^[0-9]{6}$/);
      if (regex.test(this.perpincode)) {
        this.fromState = false;
        const reportauthority = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/pincode`,
          data: {
            Pincode: `${this.perpincode}`,
          },
        };
        await axios(reportauthority)
          .then((response) => {
            this.percityOption = [];
            this.perareaOption = [];

            this.perStateData = response.data.data;
            this.perareaOption = response.data.data;
            this.perstate = response.data.data[0];
            this.percityOption.push(response.data.data[0]);

            const resArr = [];
            response.data.data.filter(function (item) {
              const i = resArr.findIndex((x) => x.City == item.City);
              if (i <= -1) {
                resArr.push(item);
              }
              return null;
            });
            this.percityOption = resArr;
          })
          .catch((error) => console.log(error, "error"));
      }
    },
    async handleCurArea() {
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getAreaByCity`,
        data: {
          City: this.curcity,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.curareaOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    clickArea(e) {
      if (this.fromCurState == true) {
        this.handleCurArea();
      }
      // this.curareaOption = []
      // this.curStateData.map((item) => {
      //   if (item.City == e) {
      //     this.curareaOption.push(item.PostOfficeName)
      //   }
      // })
    },
    async clickPincode() {
      this.curstate = "";
      this.curarea = "";
      this.curcity = "";
      this.curareaOption = [];
      this.curcityOption = [];
      const regex = new RegExp(/^[0-9]{6}$/);
      if (regex.test(this.curpincode)) {
        this.fromCurState = false;
        const reportauthority = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/pincode`,
          data: {
            Pincode: `${this.curpincode}`,
          },
        };
        await axios(reportauthority)
          .then((response) => {
            this.curcityOption = [];
            this.curareaOption = [];
            this.curStateData = response.data.data;
            this.curareaOption = response.data.data;
            this.curstate = response.data.data[0];
            const resArr = [];
            response.data.data.filter(function (item) {
              const i = resArr.findIndex((x) => x.City == item.City);
              if (i <= -1) {
                resArr.push(item);
              }
              return null;
            });
            this.curcityOption = resArr;
          })
          .catch((error) => console.log(error, "error"));
      }
    },

    async getbranch(id) {
      const branch = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getBranch`,
      };
      await axios(branch)
        .then((response) => {
          this.branchoption = response.data.data;
          this.branchoption.map((item) => {
            if (id !== undefined) {
              if (item.id == id) {
                this.branch = item;
              }
            }
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    async getparentcp(id) {
      const parentcp = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getUserByParentId/${this.loginData.id}`,
      };
      await axios(parentcp)
        .then((response) => {
          this.parentcpoption = [this.loginData];
          response.data.data.map((item) => {
            if (this.loginData.role !== "admin") {
              item.color = "success";
            }
            this.parentcpoption.push(item);
            if (item.children.length > 0) {
              this.handleChild(item.children, "info");
            }
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    handleChild(childs, color) {
      childs.map((item) => {
        if (this.role == "admin") {
          item.color = color;
        }
        this.parentcpoption.push(item);
        if (item.children.length > 0) {
          this.handleChild(
            item.children,
            color == "info" && this.role == "admin" ? "success" : ""
          );
        }
      });
    },
    async getcommission() {
      const commission = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getcommission`,
      };
      await axios(commission)
        .then((response) => {
          this.commissionOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    validatePanClick(e) {
      const regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
      this.panno = this.panno.toUpperCase();
      if (regex.test(this.panno) == true) {
        this.pannoValidate = true;
      } else {
        this.pannoValidate = false;
      }
    },
    validateAadharClick(e) {
      const regex = new RegExp(/^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}$/);
      this.aadharcardno.length == 4
        ? (this.aadharcardno = this.aadharcardno.concat(" "))
        : this.aadharcardno;
      this.aadharcardno.length == 9
        ? (this.aadharcardno = this.aadharcardno.concat(" "))
        : this.aadharcardno;
      if (regex.test(this.aadharcardno) == true) {
        this.aadharcardnoValidate = true;
      } else {
        this.aadharcardnoValidate = false;
      }
    },
    validateIFSCCodeClick(e) {
      const regex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
      this.ifsccode = this.ifsccode.toUpperCase();
      if (regex.test(this.ifsccode) == true) {
        this.ifsccodeValidate = true;
      } else {
        this.ifsccodeValidate = false;
      }
    },
    async handleFile(e, name, type) {
      this.flag = true;
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);

      const requestoption = {
        method: "POST",
        headers: {
          "Contant-type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachment`,
      };
      await axios(requestoption)
        .then((response) => {
          this.documents[name] =
            process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          this.flag = false;
        })
        .catch((error) => {
          this.flag = false;
          console.log(error, "error");
        });
    },
    onClickBack() {
      this.$router.push("/master/crm/channelpartner");
    },
    submitForm(e) {
      const data = {
        name: this.name,
        fathername: this.fathername,
        surname: this.surname,
        email: this.email,
        mobile: this.mobile,
        address: this.address,
        area: this.area,
        state: this.state,
        city: this.city,
        pincode: this.pincode,
        gender: this.gender,
        username: this.username,
        password: this.password,
        joiningdate: this.joiningdate,
        resigndate: this.resigndate,
        designation_role: this.designation_role,
        branch: this.branch.id,
        parent: this.parentcp.id,
        commissiontype: this.commissiontype ? this.commissiontype.id : 0,
        curaddressline1: this.curaddressline1,
        curaddressline2: this.curaddressline2,
        curarea: this.curarea.PostOfficeName,
        curtaluka: this.curtaluka,
        curcity: this.curcity.City,
        curpincode: this.curpincode,
        curstate: this.curstate.State,
        peraddressline1: this.peraddressline1,
        peraddressline2: this.peraddressline2,
        perarea: this.perarea ? this.perarea.PostOfficeName : "",
        pertaluka: this.pertaluka,
        percity: this.percity ? this.percity.City : "",
        perpincode: this.perpincode,
        perstate: this.perstate ? this.perstate.State : "",
        birthdate: this.birthdate,
        panno: this.panno,
        aadharcardno: this.aadharcardno,
        commission_rank: this.commission_rank ? this.commission_rank.id : "",
        bankname: this.bankname,
        bankbranch: this.bankbranch,
        bankaccno: this.bankaccno,
        ifsccode: this.ifsccode,
        photo: this.documents.photo,
        aadharfrontside: this.documents.aadharfrontside,
        aadharbackside: this.documents.aadharbackside,
        pancard: this.documents.pancard,
        cancelcheque: this.documents.cancelcheque,
        registration_doc: this.documents.registration_doc,
        profile_image: this.profileImage,
        maritalstatus: this.maritalstatus,
        anniversarydate: this.anniversarydate,
        assignto: this.assignto ? this.assignto.id : "",
      };
      if (this.ifEdit == true) {
        delete data.password;
      }
      if (this.email == this.test) {
        delete data.email;
      }
      if (this.panno == this.testpan) {
        delete data.panno;
      }
      if (this.aadharcardno == this.testaadhar) {
        delete data.aadharcardno;
      }
      // var ifEdit = this.$route.params.id ? true : false;
      this.$refs.simpleRules.validate().then(async (success) => {
        if (
          success == false ||
          this.pannoValidate == false ||
          this.ifsccodeValidate == false
        ) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Please fill in all the fields",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success && this.pannoValidate == true && this.ifsccodeValidate == true) {
          this.flag = true;

          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/channelpartner/${this.$route.params.id}`
              : `${baseApi}/channelpartner`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.flag = false;

              this.$swal({
                title: "Submited",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                    ? `${json.data.success}`
                    : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              if (this.role == "channelpartner") {
                this.$router.push("/channelpartner");
              } else {
                this.$router.push("/master/crm/channelpartner");
              }
            })
            .catch((error) => {
              this.flag = false;

              let message = "";
              const code = error.toString().includes("409");
              if (code) {
                for (let key in error.response.data.message.original) {
                  message += error.response.data.message.original[key][0] + " ";
                }
              }
              this.$swal({
                title: "Error!",
                text: code ? message : error,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
};
</script>
